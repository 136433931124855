import { ReactComponentElement } from "react";
import styled, { css } from "styled-components";
import Button from "./material-kit/Button";
import { useTypedSelector } from "hooks/useTypedRedux";

interface IModalProfileItem {
  isMessages?: boolean;
  title: string;
  icon?: ReactComponentElement<any>;
  navigate: () => void;
  to?: string;
}

const projectName = process.env.REACT_APP_INSTANCE;

const ModalProfileItem = (props: IModalProfileItem): JSX.Element => {
  const { title, icon, navigate, to, isMessages = false } = props;

  const { unviewedPopups } = useTypedSelector((state) => {
    return {
      unviewedPopups: state.userSlice.unviewedPopups,
    };
  });

  return (
    <Wrapper
      id={`profile_modal_${title}`}
      onClick={() => navigate()}
      color={"secondary"}
    >
      <ButtonMenuWrapper>
        <Icon>{icon}</Icon>
        <Text isSelected={window.location.pathname === to}>{title}</Text>
      </ButtonMenuWrapper>
      {isMessages && !!unviewedPopups.count && <MessagesStatus />}
    </Wrapper>
  );
};

export default ModalProfileItem;

const Wrapper = styled(Button)`
  min-height: 62px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 10px 12px 10px 8px;
  justify-content: space-between;

  text-transform: uppercase;
  background-color: ${({ theme }) =>
    projectName !== "megawin" ? theme.palette.secondary.main : theme.palette.text.second};

  :active {
    background-color: ${({ theme }) => theme.palette.secondary.focused};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const ButtonMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  padding: 0 8px 0 6px;
  width: 42px;

  ${projectName === "megawin" &&
  css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px 0 0;
    width: 50px;
    height: 50px;
  `}

  & > * {
    display: block;
    width: inherit;
    object-fit: cover;
  }
`;

const Text = styled.div<{ isSelected: boolean }>`
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary.main : "unset"};
  text-align: start;
`;

const MessagesStatus = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;
