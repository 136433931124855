import React from 'react';
import styled, { css } from "styled-components";
import {ReactComponent as Arrow} from "assets/images/btn_up_arrow_icon.svg"

type ArrowButtonProps = {
    onClick: () => void,
    styles?: object
}

const ArrowButton = (props: ArrowButtonProps) => {
    return (
        <Wrapper id={`arrow-button`} style={props.styles} onClick={() => props.onClick()}>
            <Arrow/>
        </Wrapper>
    );
};

export default ArrowButton;


const Wrapper = styled.div`
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  cursor: pointer;
  background: ${({theme}) => theme.palette.secondary.main};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 8px;
  filter: drop-shadow(0px 0px 4px #000000);

  ${({theme}) => theme.mode === 'light' && css`
    svg > path[fill] {
    fill: ${theme.palette.background.second}
    }
  `}

  &:hover {
    background: ${({theme}) => theme.mode === 'dark' ? '#222a49' : theme.palette.primary.hover};
  }

  &:active {
    background: ${({theme}) => theme.mode === 'dark' ? '#1c233b' : theme.palette.primary.hover};
  }
`
