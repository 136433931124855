import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTypedSelector } from "hooks/useTypedRedux";
import styled, { css } from "styled-components";
import { Mousewheel, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../../ui/material-kit/Button";
import getProviderImage from "../../helpers/providerHelper";
import useDimensions from "react-cool-dimensions";
import useMediaQuery from "hooks/useMediaQuery";
import { device } from "constants/deviceSizes";

const project = process.env.REACT_APP_INSTANCE;

interface IProviderSwiper {
  changeProvider: (provider: string) => void;
  currentProvider: string;
}

const ProvidersSwiper = (props: IProviderSwiper): JSX.Element => {
  const { changeProvider, currentProvider } = props;

  const { providers } = useTypedSelector((state) => {
    return {
      providers: state.gamesSlice.providersList.providersTv,
    };
  });

  const images = useMemo(() => {
    return [
      {
        image: undefined,
        name: "all",
      },
    ].concat(
      providers.map((item) => ({
        image: getProviderImage({ provider: item, isTvProvider: true }),
        name: item,
      }))
    );
  }, [providers]);

  const handleChoose = (item: string) => {
    changeProvider(item);
  };

  const isTablet = useMediaQuery(device.tabletM);

  const { observe, width } = useDimensions({});

  const sliderWidth = project === "megawin" ? 154 : 190;

  const columns = useMemo(() => {
    if (width > 200 * providers.length) {
      return providers.length + 1;
    } else {
      return Math.ceil(width / sliderWidth);
    }
  }, [width]);

  const initialSlice = useMemo(() => {
    return providers.findIndex((item) => item === currentProvider);
  }, [providers]);

  if (!providers.length) {
    return <div />;
  } else {
    return (
      <>
        <SwiperContainer ref={observe}>
          {isTablet && project === "megawin" ? (
            <MobileProviders>
              {images.map((item, index) => {
                return (
                  <StButton
                    onClick={() => handleChoose(item.name)}
                    color={"secondary"}
                    isSelected={currentProvider === item.name}
                    id={`provider-swiper-${item.name}`}
                  >
                    {item.image ? (
                      <Image src={item.image} alt={item.name} />
                    ) : (
                      item.name
                    )}
                  </StButton>
                );
              })}
            </MobileProviders>
          ) : (
            <SuperSwiper
              style={{ zIndex: 0 }}
              slidesPerView={columns || 1}
              spaceBetween={10}
              mousewheel={true}
              navigation={true}
              initialSlide={initialSlice !== -1 ? initialSlice : 0}
              modules={[Mousewheel, Navigation]}
            >
              {images.map((item, index) => {
                return (
                  <SwiperSlide key={`filter-${item.name}-${index}`}>
                    <StButton
                      onClick={() => handleChoose(item.name)}
                      color={"secondary"}
                      isSelected={currentProvider === item.name}
                      id={`provider-swiper-${item.name}`}
                    >
                      {item.image ? (
                        <Image src={item.image} alt={item.name} />
                      ) : (
                        item.name
                      )}
                    </StButton>
                  </SwiperSlide>
                );
              })}
            </SuperSwiper>
          )}
        </SwiperContainer>
      </>
    );
  }
};

export default ProvidersSwiper;

const MobileProviders = styled.div`
  display: flex;
  overflow-x: scroll;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
  scrollbar-width: none;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }

  *:nth-child(n) {
    min-width: 144px;
  }
`;

const SuperSwiper = styled(Swiper)`
  & .swiper-button-prev {
    :after {
      font-size: 20px;
    }

    left: 0;
    color: rgba(255, 255, 255, 0.7);
  }

  & .swiper-button-next {
    :after {
      font-size: 20px;
    }

    right: 0;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const SwiperContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  ${project === "megawin" &&
  css`
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 5px;

    .swiper-slide {
      min-width: 144px;

      > button {
        min-width: auto;
        width: 100%;
      }
    }
  `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StButton = styled(Button)`
  height: 58px;

  ${project === "megawin" &&
  css`
    color: ${({ theme }) => theme.palette.text.main};
  `}
`;
