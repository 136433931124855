import styled, { css } from "styled-components";
import React from "react";
import { useTypedSelector } from "../hooks/useTypedRedux";
import { isMobileSelector } from "../redux/selectors/appSelector";

interface IContainerTitle {
  title: string;
}

const projectName = process.env.REACT_APP_INSTANCE;

const ContainerTitle = (props: IContainerTitle) => {
  const isMobile = useTypedSelector(isMobileSelector);

  return <Wrapper isMobile={isMobile}>{props.title}</Wrapper>;
};
export default React.memo(ContainerTitle);

const Wrapper = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: ${({ isMobile }) => (isMobile ? "22px" : "24px")};
  width: fit-content;
  text-transform: uppercase;
  height: 46px;

  padding: ${({ isMobile }) =>
    `9px ${projectName !== "megawin" ? "21px" : isMobile ? "22px" : "35px"}`};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.text.second};
  color: ${({ theme }) => theme.palette.text.main};
`;
