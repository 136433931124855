import React, {useEffect, useRef, useState} from 'react';


import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
import styled from 'styled-components';
import LazyImage from "../../ui/LazyImage";
import {useTranslation} from "react-i18next";
import {IBanner} from "../../redux/types/appTypes";
import {useNavigate} from "react-router-dom";
import {device} from "../../constants/deviceSizes";

type SliderProps = {
    slides: {
        imageUrl: string
        link: string
        lang: string
    }[]
}

const Slider = (props: SliderProps): JSX.Element => {
    const {t, i18n} = useTranslation();
    const pagination = {
        "clickable": true,
        "renderBullet": function (index: number, className: string) {
            return '<span class=\"' + className + '\">' + '</span>';
        },

    }
    const navigate = useNavigate();

    return (
        <>
            <SuperSwiper
                key={`${i18n.language}`}
                centeredSlides={true}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                speed={1000}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={pagination}
                className="mySwiper"
            >
                {props.slides.map((item, index) => (
                    <SwiperSlide key={`banner-${index}-${i18n.language}`}
                                 onClick={() => navigate(item.link)}>
                        <Image src={item.imageUrl}/>
                    </SwiperSlide>
                ))}
            </SuperSwiper>
        </>
    );
};

export default Slider;


const Image = styled(LazyImage)`
  width: 100%;
  min-width: 100px;
  min-height: 200px;
  max-height: 520px;
  height: 100%;
`

const SuperSwiper = styled(Swiper)`
  margin: 0;
  width: 100%;
  & .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    span {
      width: 100%;
      @media (${device.laptopL}) {
        width: auto;
      }
    }
    box-sizing: border-box;
  }

  & .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
    width: 10px;
    margin: 7px !important;
    height: 10px;
  }

  & .swiper-pagination {
    text-align: center;
    position: absolute;
    z-index: 100;

    left: 0;
    right: 0;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  & .swiper-pagination-bullet-active {
    background: ${({theme}) => theme.palette.primary.main};
  }

  & .swiper-button-prev {
    color: ${({theme}) => theme.palette.primary.main};
  }

  & .swiper-button-next {
    color: ${({theme}) => theme.palette.primary.main};
  }

  & .swiper-button-disabled {
    pointer-events: auto!important;
  }
`
