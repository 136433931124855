import { useTranslation } from "react-i18next";
import Input from "../../ui/material-kit/Input";
import useInput from "../../hooks/useInput";
import useValidate from "../../hooks/useValidate";
import useTestValidation from "../../hooks/useTestValidation";
import { useUpdateMyPasswordMutation } from "../../redux/api/userApi";
import Button from "../../ui/material-kit/Button";
import styled, { useTheme } from "styled-components";
import { validateExpression } from "../../constants/helperValidation";
import { isMobileSelector } from "redux/selectors/appSelector";
import { useTypedSelector } from "hooks/useTypedRedux";

export const ChangePasswordInfo = (): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [updateMyPassword] = useUpdateMyPasswordMutation();
  const isMobile = useTypedSelector(isMobileSelector)

  const fields = {
    password: t(`common.placeholder.old_password_field`),
    newPassword: t(`common.placeholder.new_password_field`),
    repeatPassword: t(`common.placeholder.repeat_password_field`),
    change: t(`pages.profile.change`),
    incorrect_passwords: t(`messages.errors.passwords_not_match`),
  };

  const password = useInput("");
  const newPassword = useInput("");
  const repeatPassword = useInput("");

  const checkValidate = useTestValidation({
    validateObject: {
      passwordValidate: useValidate(
        password.value,
        validateExpression.password
      ),
      newPasswordValidate: useValidate(
        newPassword.value,
        validateExpression.password
      ),
      repeatPasswordValidate: useValidate(
        repeatPassword.value,
        validateExpression.password,
        [
          {
            status: newPassword.value !== repeatPassword.value,
            message: fields.incorrect_passwords,
          },
        ],
        [newPassword.value]
      ),
    },
    callback: () =>
      updateMyPassword({
        data: {
          password: password.value,
          newPassword: newPassword.value,
          repeatPassword: repeatPassword.value,
        },
        toast: "all",
      }),
    callbackSuccess: () => {
      checkValidate.clear();
    },
  });

  return (
    <>
      <StInput
        {...password}
        type={"password"}
        placeholder={fields.password}
        inputExtra={`${
          theme.mode === "light" && !isMobile &&
          `background-color: ${theme.palette.primary.white}`
        }`}
        {...checkValidate.validate["passwordValidate"]}
      />
      <StInput
        {...newPassword}
        type={"password"}
        placeholder={fields.newPassword}
        inputExtra={`${
          theme.mode === "light" && !isMobile &&
          `background-color: ${theme.palette.primary.white}`
        }`}
        {...checkValidate.validate["newPasswordValidate"]}
      />
      <StInput
        {...repeatPassword}
        type={"password"}
        placeholder={fields.repeatPassword}
        inputExtra={`${
          theme.mode === "light" && !isMobile &&
          `background-color: ${theme.palette.primary.white}`
        }`}
        {...checkValidate.validate["repeatPasswordValidate"]}
      />

      <StyledButton
        onClick={() => checkValidate.checkValidation()}
        id={"password-change"}
      >
        {fields.change}
      </StyledButton>
    </>
  );
};

const StyledButton = styled(Button)`
  width: fit-content;
  justify-self: center;
  font-size: 20px;
`;

const StInput = styled(Input)`
  font-size: 10px;

  ::placeholder {
    font-size: 10px;
  }
`;
