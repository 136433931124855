import React from "react";
import styled, { css } from "styled-components";
import LazyImage from "ui/LazyImage";
import { useTranslation } from "react-i18next";

import Button from "ui/material-kit/Button";
import { useTypedSelector } from "../../hooks/useTypedRedux";
import useDimensions from "react-cool-dimensions";
import { userSelector } from "../../redux/selectors/userSelector";
import { isMobileSelector } from "../../redux/selectors/appSelector";
import { device } from "constants/deviceSizes";

type DesktopCardProps = {
  onClick: () => void;
  icon: string;
  title: string;
  color?: string;
};

const projectName = process.env.REACT_APP_INSTANCE;

const DesktopCard = (props: DesktopCardProps) => {
  const { onClick, icon, title, color } = props;
  const { t } = useTranslation();
  let img = require(`../../${icon}`);

  const isMobile = useTypedSelector(isMobileSelector);

  return (
    <Icon
      onClick={onClick}
      color={"secondary"}
      isMobile={isMobile}
      id={`card-${title}`}
    >
      <>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Image src={img} isMobile={isMobile} color={color} />
        </div>
        <Title isMobile={isMobile}>{title}</Title>
      </>
    </Icon>
  );
};

export default DesktopCard;

const Icon = styled(Button)<{ isMobile: boolean }>`
  display: flex;
  min-width: 100px;
  max-width: 250px;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.main_card.background};
  ${({ isMobile }) =>
    !isMobile &&
    projectName === "megawin" &&
    css`
      gap: 18px;
    `}

  :hover {
    background: ${({ theme }) => theme.palette.secondary.focused};
  }

  :focus {
    background: ${({ theme }) => theme.palette.secondary.focused};
  }

  padding: ${({ isMobile }) =>
    projectName !== "megawin" ? "10px" : !isMobile ? "10px" : "7px"};
  border-radius: 10px;
  align-items: center;

  ${({ theme }) =>
    projectName !== "megawin" &&
    theme.mode === "light" &&
    css`
      @media (${device.mobileL}) {
        &:last-child {
          img {
            filter: invert(1);
          }
        }
      }
    `}
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-size: ${({ isMobile }) => (isMobile ? "16px" : "24px")};
  text-transform: uppercase;
  font-weight: 500;
  height: 26px;
  display: flex;
  align-items: self-end;
`;

const Image = styled(LazyImage)<{ isMobile: boolean; color?: string }>`
  display: block;
  max-width: ${({ isMobile }) =>
    projectName !== "megawin" ? (isMobile ? "55px" : "110px") : "unset"};
  max-height: ${({ isMobile }) =>
    projectName !== "megawin" ? (isMobile ? "55px" : "110px") : "unset"};
  height: 100%;

  & svg > path {
    fill: ${({ color }) => color};
  }
`;
