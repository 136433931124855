import styled, { css } from "styled-components";
import Modal from "../../ui/material-kit/Modal";
import routingLinks from "../../constants/routingLinks";
import IconButton from "../../ui/material-kit/IconButton";
import { ReactComponent as ExitIcon } from "../../assets/images/cross_icon.svg";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../hooks/useTypedRedux";
import { updateModal } from "../../redux/slices/appSlice";
import { clearTransactions } from "../../redux/slices/transactionSlice";
import { ReactNode } from "react";

interface IMobileWrapper {
  icon?: ReactNode;
  title: string;
  childrenInfo: ReactNode;
  childrenResult?: ReactNode;
  count?: number;
}

const projectName = process.env.REACT_APP_INSTANCE;

const MobileWrapper = (props: IMobileWrapper) => {
  const { title, childrenInfo, childrenResult, count } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const route = routingLinks.find((item) => item.key === title);
  const { isOpen } = useTypedSelector((state) => {
    return {
      isOpen: state.appSlice.modal,
    };
  });
  const dispatch = useTypedDispatch();

  return (
    <>
      <Wrapper>
        <Header>
          <div />
          {t(`common.profile.${route?.lang_key}`)}
          <IconButton id={`exit-icon`} onClick={() => navigate("/")}>
            <ExitIcon />
          </IconButton>
        </Header>

        <InfoBlock isMessages={location.pathname === "/profile/messages"}>
          <Image>{route?.iconJsx}</Image>
          {childrenInfo}
        </InfoBlock>
      </Wrapper>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          dispatch(clearTransactions());
          dispatch(updateModal(false));
        }}
        title={t(`common.profile.${route?.lang_key}`)}
        isFullScreen={true}
        style={{ overflow: "hidden" }}
      >
        {childrenResult ? (
          <ResultBlock transparent={count !== 0} id={`scrollableDivOperations`}>
            {count ? childrenResult : t(`common.info.no_data_for_this_interval`)}
          </ResultBlock>
        ) : (
          <div />
        )}
      </Modal>
    </>
  );
};

export default MobileWrapper;

const Wrapper = styled.div`
  height: fit-content;
  display: grid;
  grid-template-rows: min-content;
  grid-column-gap: 68px;
  box-sizing: border-box;
  border-radius: 20px;
`;

const InfoBlock = styled.div<{ isMessages: boolean }>`
  box-sizing: border-box;
  padding: ${({ isMessages }) => (isMessages ? "0 10px" : "0 46px")};
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 10px;
  max-width: 500px;
  width: 100%;
  justify-self: center;
  justify-content: ${({ isMessages }) => isMessages && "center"};

`;

const ResultBlock = styled.div<{ transparent: boolean }>`
  border-radius: 0;
  display: grid;
  grid-row-gap: 15px;
  overflow: auto;
  text-transform: uppercase;
  min-width: 300px;
  width: 100%;

  ${({ transparent }) =>
    !transparent &&
    css`
      height: 100%;
      border-radius: 20px;
      justify-items: center;
      align-items: center;
      background-color: ${({ theme }) => theme.palette.background.main};
      font-size: 16px;
      color: ${({ theme }) => theme.palette.secondary.focused};
    `}
`;

const Image = styled.div`
  width: 160px;
  height: 160px;
  justify-self: center;

  & > * {
    margin-inline: auto;
    display: block;
    
    ${
      projectName !== "megawin" &&
      css`
        width: 100%;
      `
    }
    height: 100%;
  }

    g > path {
      fill: #363636;
    }
  }
`;

const Header = styled.div`
  padding: 18px 18px 30px;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  justify-items: center;
  text-transform: uppercase;
`;
