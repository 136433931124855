import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ReactComponent as MenuIcon } from "assets/images/menu_drawer_icon.svg";
import { useNavigate } from "react-router-dom";
import InfoContainer from "components/wrapper/header/InfoContainer";
import styled, { css, useTheme } from "styled-components";
import { getStorage } from "../../../hooks/useStorage";
import IconButton from "../../../ui/material-kit/IconButton";
import ImageIcon from "../../../ui/material-kit/ImageIcon";
import SignInModal from "../../modals/SignInModal";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import ProfileModal from "../../modals/ProfileModal";
import useOutsideClick from "../../../hooks/useOutsideClick";
import CustomDrawer from "../drawer";
import { userIdSelector } from "../../../redux/selectors/userSelector";
import { device } from "constants/deviceSizes";
import { useTranslation } from "react-i18next";

const projectName = process.env.REACT_APP_INSTANCE;

const HeaderMobile = () => {
  const theme = useTheme();
  const [isOpenDrawer, setOpenDrawer] = useState(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isOpenLogin, setOpenLogin] = useState<boolean>(false);
  console.log("🚀 ~ HeaderMobile ~ isOpenLogin:", isOpenLogin);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRight = i18n.dir() === "rtl";

  const handleChangeDrawer = useCallback(
    (e: any) => {
      e.stopPropagation();
      setOpenDrawer((prev) => !prev);
    },
    [setOpenDrawer]
  );

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, [setOpenDrawer]);

  const handleChangeProfile = useCallback(() => {
    //  console.log(isOpen, 'close')
    setOpen((prev) => !prev);
  }, [setOpen, isOpen]);

  const handleCloseProfile = useCallback(() => {
    setOpen(false);
    // console.log('close')
  }, [setOpen]);

  const handleNavigateToMain = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const PATH = getStorage("APP_SEO.LOGO_PATH");
  const logo = require(`../../../${PATH[theme.mode.toLocaleUpperCase()]}`);
  const userId = useTypedSelector(userIdSelector);

  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (userId) setOpen(false);
  });

  const Logo = useMemo(() => {
    return (
      <LogoIcon
        src={logo}
        alt={"desktop-logo"}
        onClick={handleNavigateToMain}
        style={projectName === "megawin" ? { maxWidth: 150 } : {}}
      />
    );
  }, [handleNavigateToMain, logo]);

  return (
    <>
      <CustomAppBar onClick={handleCloseDrawer} isRight={isRight}>
        <MenuButton id={`open-drawer`} onClick={handleChangeDrawer}>
          <MenuIcon />
        </MenuButton>

        {Logo}

        <ProfileWrapper>
          {userId ? (
            <div style={{ position: "relative" }} ref={ref}>
              <div onClick={handleChangeProfile}>
                {isOpen && <Background />}

                <InfoContainer />
              </div>
              {isOpen && userId && (
                <ProfileModal
                  isOpen={isOpen}
                  onClose={handleCloseProfile}
                  isMobile={true}
                  isAbsolute={true}
                />
              )}
            </div>
          ) : (
            <div style={{ position: "relative" }}>
              {!isOpen && (
                <StyledButton
                  id={`login-open-modal`}
                  color={"primary"}
                  onClick={() => {
                    setOpenLogin(true);
                  }}
                >
                  <>{t(`common.header.login`)}</>
                </StyledButton>
              )}
            </div>
          )}
        </ProfileWrapper>
      </CustomAppBar>
      <SignInModal
        isOpen={isOpenLogin}
        onClose={() => setOpenLogin(false)}
        isFullScreen={true}
      />
      <CustomDrawer open={isOpenDrawer} setClosed={handleCloseDrawer} />
    </>
  );
};

export default React.memo(HeaderMobile);

const CustomAppBar = styled.header<{ isRight?: boolean }>`
  position: fixed;
  display: grid;
  grid-template-columns: ${projectName === "mybet"
    ? "auto 1fr 1fr"
    : "repeat(3, 1fr)"};
  ${() =>
    projectName === "mybet" &&
    css`
      gap: 8px;
    `}
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  top: 0;
  z-index: 10000;
  height: 44px;
  color: #fff;
  background: ${({ theme }) => theme.palette.background.mobileHeader};
  //box-shadow: 0px 0px 30px rgb(0 0 0 / 51%);
  ${({ isRight }) =>
    projectName === "megawin" &&
    css`
      display: flex;
      gap: 8px;
      justify-content: start;
      > *:last-child {
        ${isRight ? "margin-right" : "margin-left"}: auto;
      }
    `}
`;

const MenuButton = styled(IconButton)`
  padding-inline-start: 12px;

  & > svg > path[stroke] {
    stroke: ${({ theme }) => theme.palette.text.main};
  }
`;

const LogoIcon = styled(ImageIcon)`
  justify-self: center;
  max-width: 150px;

  @media (${device.tabletM}) {
    justify-self: ${projectName === "mybet" ? "start" : "center"};
  }
`;

const StyledButton = styled(IconButton)`
  box-sizing: border-box;
  padding: ${projectName === "megawin" ? "8px 16px" : "8px"};
  white-space: nowrap;
  margin-inline-end: 10px;
  height: 30px;
  border-radius: 5px;
  text-transform: uppercase;
`;

const ProfileWrapper = styled.div`
  justify-self: end;
  cursor: pointer;
`;

const Background = styled.div`
  width: 200vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 1;
  top: 44px;
  background-color: ${({ theme }) => theme.palette.primary.black};
  transition: all 0.2s;
  opacity: 0.5;
`;
