import { DefaultTheme } from "styled-components";
import IzibetTheme from "./IzibetTheme";
import MegawinTheme from "./MegawinTheme";

const projectName = process.env.REACT_APP_INSTANCE ?? "izibet";

const themes: {
  [key: string]: { dark: DefaultTheme; light?: DefaultTheme };
} = {
  izibet: IzibetTheme,
  mybet: IzibetTheme,
  megawin: MegawinTheme,
};

export const getTheme = (): { dark: DefaultTheme; light?: DefaultTheme } => {
  return themes[projectName];
};
