import styled from "styled-components";
import Navbar from "./NavBar";
import { memo } from "react";

const SecondHeaderDesktop = () => {
  return (
    <Wrapper>
      <Navbar />
    </Wrapper>
  );
};

export default memo(SecondHeaderDesktop);

const Wrapper = styled.div`
  height: 68px;
  background: ${({ theme }) => theme.palette.background.secondHeader};
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;
