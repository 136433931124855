import { Fragment, memo, useState } from "react";
import styled from "styled-components";
import { useTypedSelector } from "../hooks/useTypedRedux";
import PromotionModal from "../components/modals/PromotionModal";
import EmptyIcon from "assets/images/animated_loading_icon.svg";
import { useTranslation } from "react-i18next";

interface IPromotions {
  isDesktop?: boolean;
}

const Promotions = (props: IPromotions) => {
  const [isOpen, setOpen] = useState(false);
  const [index, setIndex] = useState(-1);
  const { t } = useTranslation();
  const { promotions, isMobile } = useTypedSelector((state) => {
    return {
      isMobile: state.appSlice.isMobile,
      promotions: state.appSlice.promotions,
    };
  });

  return (
    <>
      <Wrapper isMobile={isMobile}>
        <div>
          <PageTitle>{t(`common.modals.promotions.promotions`)}</PageTitle>
          <Content isMobile={isMobile}>
            {promotions.map((item, ind) => (
              <Fragment key={`promotion-${ind}-${item.title}`}>
                <Item
                  src={item.imageUrl || EmptyIcon}
                  alt={"promo"}
                  onClick={() => {
                    setIndex(ind);
                    setOpen(true);
                  }}
                />
              </Fragment>
            ))}
          </Content>
        </div>
      </Wrapper>
      <PromotionModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        promotion={promotions[index] || {}}
        isMobile={isMobile}
      />
    </>
  );
};

export default memo(Promotions);

const Wrapper = styled.div<{ isMobile: boolean }>`
  box-sizing: border-box;
  padding: ${({ isMobile }) => (isMobile ? "25px 20px" : "25px 57px")};
  position: relative;
  align-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div<{ isMobile?: boolean }>`
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 30px;
  grid-auto-rows: fit-content(150px);
  grid-template-columns: ${({ isMobile }) =>
    isMobile
      ? "repeat(1, minmax(230px, 400px))"
      : "repeat(4, minmax(230px, 419px))"};
`;

const Item = styled.img`
  border-radius: 5px;
  object-fit: cover;
  width: 100%;
  cursor: pointer;
`;

const PageTitle = styled.div`
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 24px;
  width: fit-content;
  text-transform: uppercase;
  height: 46px;
  margin-bottom: 25px;
  padding: 9px 21px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.palette.text.second};
  color: ${({ theme }) => theme.palette.text.main};
`;
