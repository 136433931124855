import React, { useRef, useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { getStorage } from "../hooks/useStorage";
import { useTranslation } from "react-i18next";
import { ReactComponent as DropIcon } from "assets/images/header_drop-down-button_icon.svg"
import IconButton from "./material-kit/IconButton";
import LanguageController from "../components/wrapper/header/LanguageContainer";
import useOutsideClick from "../hooks/useOutsideClick";
import { useTypedSelector } from "../hooks/useTypedRedux";

interface IListMenu {
    title: string,
    icon?: React.ReactElement,
    click?: () => void
    isLang?: boolean,

}

const ListMenu = (props: IListMenu): JSX.Element => {
    const { title, icon, click, isLang } = props;
    const { t, i18n } = useTranslation();

    const { langs } = useTypedSelector(state => {
        return {
            langs: state.appSlice.languages
        }
    })

    const languages = getStorage("APP_OTHER.LANGUAGES");
    let languagesArr: any = Object.values(languages).filter((item: any) => item.VISIBLE);
    const languageData: { title: string, icon: string }[] = languagesArr.map((item: any) => ({
        title: item.KEY,
        icon: require(`../${item.ICON_PATH}`)
    }));
    const curLang = languageData.find(item => item.title === i18n.language);
    const [isOpen, setOpen] = useState(false);

    const refDesk = useRef(null);
    useOutsideClick(refDesk, () => setOpen(false))

    if (!isLang) {
        return (
            <Wrapper onClick={() => click && click()}>
                <Icon>{icon}</Icon>
                <Text>{title}</Text>
            </Wrapper>
        );
    } else {
        return (
            <>
                <Wrapper ref={refDesk} onClick={() => setOpen(!isOpen)}>
                    <Icon><img style={{ width: 20 }} src={curLang?.icon} alt={curLang?.title} /></Icon>
                    <Text>{title}</Text>
                    <ArrowDrop isOpen={isOpen} />

                    {isOpen &&
                        <Menu id="positioned-menu">
                            {languageData.sort((a, b) =>
                                (+a.title.includes(i18n.language) - +b.title.includes(i18n.language)))
                                .reverse().map((item, index) => (
                                    <React.Fragment key={index + "lang" + item.title}>
                                        <MenuItem isSelected={item.title === i18n.language} onClick={async () => {
                                            await i18n.changeLanguage(item.title)
                                            setOpen(false)
                                        }}>
                                            <Icon><img style={{ width: 20 }} src={item?.icon} alt={item?.title} /></Icon>
                                            {langs.find(lang => lang.code === item.title)?.title}
                                        </MenuItem>
                                    </React.Fragment>))}
                        </Menu>}

                </Wrapper>
            </>
        );
    }

};

export default ListMenu;

const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.text.main};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: 14px;
`

const Icon = styled.div`
  padding: 0 10px;
  max-width: 25px;
  max-height: 25px;
`

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Menu = styled.div`
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.palette.background.main};
  border-radius: 5px;
  position: absolute;
`


const MenuItem = styled.div<{ isSelected?: boolean }>`
  height: 56px;
  display: flex;
  cursor: pointer;
  align-items: center;
  min-height: 40px;
  padding: 0 6px;
  flex-direction: row;
  background-color: ${({ isSelected, theme }) => isSelected && theme.palette.secondary.main};

  & > svg {
    width: 26px;
    height: 26px;
  }
`

const ArrowDrop = styled(DropIcon) <{ isOpen: boolean }>`
  position: absolute;
  right: 20px;
  z-index: 100000;
  transition: 0.3s;
  transform: ${({ isOpen }) => isOpen && 'rotate( 180deg)'};
`