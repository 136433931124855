import { memo } from "react";
import styled, { css } from "styled-components";
import { useTypedSelector } from "../../../hooks/useTypedRedux";
import { useTranslation } from "react-i18next";
import { getStorage } from "../../../hooks/useStorage";
import List from "./List";
import { ReactComponent as PrivacyIcon } from "assets/images/footer_privacy_icon.svg";
import { ReactComponent as FacebookIcon } from "assets/images/header_facebook_logo.svg";
import FooterColumnContainer from "../../../ui/FooterColumnContainer";
import routing from "constants/routingLinks";
import SocialMedia from "components/socialMedia";

interface Page {
  title: string;
  path: string;
}

const project = process.env.REACT_APP_INSTANCE;

const FooterDesktop = (): JSX.Element => {
  const { i18n, t } = useTranslation();
  const FOOTER = getStorage("APP_OTHER.FOOTER.MENU");
  const footerBets = Object.entries(FOOTER)
    .filter((item) => item[1] === true)
    .map((item) => {
      let route = routing.find((it) => it.key === item[0]);
      return {
        title: t(`common.navbar.${route?.lang_key}`) || "page",
        path: route?.to || "/",
        isAuth: route?.isAuth,
      };
    });
  const { pages } = useTypedSelector((state) => {
    return {
      pages: state.appSlice.pages
        .filter((item) => item.lang === i18n.language)
        .map((item) => ({
          title: item.title,
          path: `/static/${item.url}`,
          isAuth: false,
        })),
    };
  });

  const fields = {
    bets: t(`common.footer.bets`),
    info: t(`common.footer.info`),
    social_media: t(`common.footer.social_media`),
    privacy: t(`common.footer.privacy`, {
      platform: getStorage("APP_SEO.TITLE"),
    }),
  };

  return (
    <Footer>
      <FooterColumnContainer title={fields.bets}>
        <List pages={footerBets} />
      </FooterColumnContainer>

      <FooterColumnContainer title={fields.info}>
        <List pages={pages} />
      </FooterColumnContainer>

      <FooterColumnContainer title={fields.social_media}>
        <SocialMedia
          type="secondary"
          include={project !== "megawin" ? ["FACEBOOK"] : undefined}
        />
      </FooterColumnContainer>

      <FooterColumnContainer title={fields.privacy}>
        <PrivacyIcon />
      </FooterColumnContainer>
    </Footer>
  );
};

export default memo(FooterDesktop);

const Footer = styled.footer`
  box-sizing: border-box;
  left: 0;
  right: 0;
  bottom: 0;
  grid-template-rows: minmax(280px, 1fr);
  padding-bottom: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.text.second};
  color: ${({ theme }) => theme.palette.secondary.disabled};
  display: grid;
  grid-template-columns: repeat(3, 2fr) 5fr;
  justify-items: center;
  text-align: center;
`;

const FbIcon = styled(FacebookIcon)`
  filter: grayscale(100%) invert(1);
  :hover {
    filter: inherit;
  }
`;
