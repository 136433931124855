import { Fragment, useCallback } from "react";
import styled, { css } from "styled-components";
import ModalProfileItem from "../../ui/ModalProfileItem";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LogoutIcon } from "assets/images/menu_profile/menu_profile_logout_icon.svg";
import MegawinLogoutIcon from "assets/images/menu_profile/megawin_menu_profile_logout_icon.webp";
import { ReactComponent as CopyIcon } from "assets/images/copy_icon.svg";
import { useTranslation } from "react-i18next";
import IconButton from "../../ui/material-kit/IconButton";
import { useLogoutMeMutation } from "../../redux/api/userApi";
import { toast } from "react-toastify";
import { ProfileLinks } from "ui/ProfileLinks";

interface IProfileModal {
  isOpen: boolean;
  onClose: () => void;
  login?: string;
  id?: number | string;
  isMobile?: boolean;
  isAbsolute?: boolean;
}

const projectName = process.env.REACT_APP_INSTANCE;

const ProfileModal = (props: IProfileModal) => {
  const { isOpen, onClose, login, id, isMobile, isAbsolute } = props;
  const { t, i18n } = useTranslation();

  const fields = {
    username: t(`common.placeholder.username`),
    password: t(`common.placeholder.password`),
    sing_in: t(`common.modals.sing_in.sing_in`),
    login_now: t(`common.modals.sing_in.login_now`),
    id_copied: t(`messages.success.id_copied`),
  };

  const navigate = useNavigate();

  const handleCopy = useCallback(() => {
    navigator.clipboard
      .writeText(`ID: ${id}`)
      .then(() => toast.success(fields.id_copied));
  }, [fields.id_copied, id]);

  const [logoutMe] = useLogoutMeMutation();

  // useEffect(() => {
  //   function disableTouchScroll(e: any) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //     return false;
  //   }

  //   document.getElementById("root")!.style.overflowY = "hidden";
  //   document
  //     .getElementById("root")!
  //     .addEventListener("touchmove", disableTouchScroll);
  //   return () => {
  //     document.getElementById("root")!.style.overflowY = "auto";
  //     document
  //       .getElementById("root")!
  //       .removeEventListener("touchmove", disableTouchScroll);
  //   };
  // }, []);

  return (
    <Wrapper
      isRight={i18n.dir() === "rtl"}
      isMobile={isMobile}
      isAbsolute={isAbsolute}
    >
      {!isMobile && isAbsolute && (
        <Header>
          <Login>{login}</Login>
          <Id>
            <div>
              {t(`common.header.id`)}: {id}
            </div>
            <IconButton
              id={`copy-icon`}
              onClick={handleCopy}
              style={{ marginRight: 4 }}
            >
              <CopyIcon />
            </IconButton>
          </Id>
        </Header>
      )}
      <ProfileLinks onClose={onClose} />
      <div />

      <Fragment key={`logout-menu-profile`}>
        <ModalProfileItem
          title={t(`common.profile.logout`)}
          icon={
            projectName !== "megawin" ? (
              <LogoutIcon />
            ) : (
              <img src={MegawinLogoutIcon} />
            )
          }
          navigate={() => {
            logoutMe().then((pld) => {
              navigate(`/`);
              window.location.reload();
            });
          }}
        />
      </Fragment>
    </Wrapper>
  );
};

export default ProfileModal;

const Wrapper = styled.div<{
  isMobile?: boolean;
  isAbsolute?: boolean;
  isRight?: boolean;
}>`
  display: grid;
  grid-gap: 20px;
  z-index: ${({ isAbsolute }) => (isAbsolute ? 1000000 : 1)};
  position: ${({ isAbsolute }) => (isAbsolute ? "absolute" : "static")};
  width: 318px;
  height: fit-content;
  padding: 12px;
  border-radius: 5px;
  box-sizing: border-box;
  right: 0;
  background-color: ${({ theme }) => projectName !== 'megawin' ? theme.palette.background.header : theme.palette.background.second};
  text-transform: uppercase;
  ${({ isRight }) =>
    isRight &&
    css`
      right: unset;
      left: 0;
    `}
  ${({ isMobile, isAbsolute }) =>
    !isMobile
      ? css`
          margin: 0;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          max-height: calc(100vh - 85px);
          overflow-x: hidden;
          overflow-y: auto;
          top: 57px;
          ${({ theme }) =>
            theme.mode === "light" && isAbsolute &&
            css`
              box-shadow: 0px 2px 10px 0px #000000;
            `}
        `
      : css`
          top: 44px;
          border-radius: 0;
        `}
`;

const Header = styled.div`
  height: 80px;
  display: grid;
  align-content: space-between;
`;
const Login = styled.div`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: 24px;
`;

const Id = styled.div`
  display: flex;
  font-size: 20px;
  justify-content: space-between;

  color: ${({ theme }) => theme.palette.text.main};
  & svg > path[fill] {
    fill: ${({ theme }) => theme.palette.text.main};
  }
`;
