import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import Search from "../../ui/material-kit/Search";
import useInput from "../../hooks/useInput";
import CategoriesContainer from "../../components/containers/CategoriesContainer";
import { useDebounce } from "../../hooks/useDebounce";
import GamesVirtualListContainer from "./GamesVirtualListContainer";
import Button from "../../ui/material-kit/Button";
import ProvidersModal from "../../components/modals/ProvidersModal";
import useMediaQuery from "hooks/useMediaQuery";
import { device } from "constants/deviceSizes";
import ProviderAccordion from "ui/ProviderAccordion";

const project = process.env.REACT_APP_INSTANCE;

const GamesPageContainer = () => {
  const { t } = useTranslation();
  const search = useInput("");

  const [isOpen, setOpen] = useState<boolean>(false);

  const fields = {
    search: t(`common.placeholder.search_games`),
    show_providers: t(`pages.games.show_providers`),
  };

  const isMobile = useMediaQuery(`(${device.tabletS})`);

  const [searchParams, setSearchParams] = useSearchParams();
  const [provider, setProvider] = useState<string>(
    searchParams.get("provider") || ""
  );
  const findGames = useDebounce(search.value, 1000);
  const [categoryFilter, setCategoryFilter] = useState<string>(
    searchParams.get("category") || ""
  );

  const changeCategory = useCallback(
    (v: string) => {
      setSearchParams({ provider: provider, category: v });
    },
    [categoryFilter, provider, setSearchParams]
  );

  const changeProvider = useCallback(
    (v: string) => {
      if (v === provider) {
        setSearchParams({ provider: "", category: categoryFilter });
        return;
      }
      setSearchParams({ provider: v, category: categoryFilter });
    },
    [categoryFilter, provider, setSearchParams]
  );

  useEffect(() => {
    const prov = searchParams.get("provider") || "";
    const cat = searchParams.get("category") || "";
    if (prov !== provider) setProvider(prov);
    if (cat !== categoryFilter) setCategoryFilter(cat);
  }, [searchParams]);

  const memoValue = useMemo(() => {
    return {
      game: findGames || undefined,
      provider: provider || undefined,
      category: categoryFilter || undefined,
    };
  }, [findGames, provider, categoryFilter]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Wrapper>
        <Block>
          {project === "megawin" ? (
            <SearchWrapper>
              <Search search={search} title={fields.search} isMobile={true} />
            </SearchWrapper>
          ) : (
            <Search search={search} title={fields.search} isMobile={true} />
          )}
          {project === "megawin" && !isMobile ? (
            <ProviderAccordion
              changeProvider={changeProvider}
              currentProvider={provider}
            />
          ) : (
            <StButton
              color={"secondary"}
              onClick={handleOpen}
              id={`game-search`}
            >
              {fields.show_providers}
            </StButton>
          )}
        </Block>
        <CategoriesContainer
          onChange={changeCategory}
          category={categoryFilter}
        />
        <div style={{ margin: project === "megawin" ? "0 -2px" : "0 -4px" }}>
          <GamesVirtualListContainer value={memoValue} />
        </div>
      </Wrapper>
      {isOpen && (
        <ProvidersModal
          isOpen={isOpen}
          onClose={handleClose}
          onChange={changeProvider}
          provider={provider}
        />
      )}
    </>
  );
};

export default GamesPageContainer;

const SearchWrapper = styled.div`
  max-width: 354px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-gap: 18px;

  ${project === "megawin" &&
  css`
    grid-gap: 14px;
    padding: 14px 4px;

    @media (${device.tabletS}) {
      grid-gap: 6px;
      padding: 2px 0;
    }
  `}
`;

const Block = styled.div`
  display: flex;
  grid-gap: 10px;

  ${project === "megawin" &&
  css`
    flex-direction: column;
    gap: 14px;

    @media (${device.tabletS}) {
      flex-direction: row;
      gap: 6px;

      > * {
        width: 100%;
        height: 36px;
      }
    }
  `}
`;

const StButton = styled(Button)`
  padding: 0;
  font-size: 14px;
`;
