import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAlert, IAppState, IJackpot, ThemeMode } from "../types/appTypes";
import { appAPI } from "../api/appApi";

const initialState: IAppState = {
  pages: [],
  banners: [],
  promotions: [],
  jackpot: [],
  alert: {
    status: "error",
    message: "",
    isOpen: false,
  },
  isMobile: false,
  languages: [],
  modal: false,
  isCleanCache: false,
  themeMode: (localStorage.getItem(`${process.env.REACT_APP_INSTANCE}_theme`) ??
    "dark") as ThemeMode,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateAlert(state, action: PayloadAction<IAlert>) {
      state.alert = action.payload;
    },
    isMobileTrue(state) {
      state.isMobile = true;
    },
    updateModal(state, action: PayloadAction<boolean>) {
      state.modal = action.payload;
    },
    updateJackpot(state, action: PayloadAction<IJackpot[]>) {
      state.jackpot = action.payload;
    },
    updateCleanCache(state, action: PayloadAction<boolean>) {
      state.isCleanCache = action.payload;
    },
    setThemeMode(state, action: PayloadAction<ThemeMode>) {
      state.themeMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        appAPI.endpoints.getStaticPages.matchFulfilled,
        (state, { payload }) => {
          state.pages = payload;
        }
      )
      // .addMatcher(
      //     appAPI.endpoints.getStaticPage.matchFulfilled,
      //     (state, {payload}) => {
      //         state.page = payload
      //     })
      .addMatcher(
        appAPI.endpoints.getLanguages.matchFulfilled,
        (state, { payload }) => {
          // console.log('reducer getLanguages = ', payload)
          state.languages = payload;
        }
      )
      .addMatcher(
        appAPI.endpoints.getAllPromotions.matchFulfilled,
        (state, { payload }) => {
          // console.log('promotions = ', payload)
          state.promotions = payload;
        }
      )
      .addMatcher(
        appAPI.endpoints.getAllBanners.matchFulfilled,
        (state, { payload }) => {
          // console.log('banners = ', payload)
          state.banners = payload;
        }
      )
      .addMatcher(
        appAPI.endpoints.getJackpot.matchFulfilled,
        (state, { payload }) => {
          // console.log('banners = ', payload)
          state.jackpot = payload;
        }
      );
  },
});

export const {
  updateAlert,
  isMobileTrue,
  updateModal,
  updateJackpot,
  updateCleanCache,
  setThemeMode,
} = appSlice.actions;

export default appSlice.reducer;
