import { ReactComponent as LightThemeIcon } from "assets/images/theme/light.svg";
import { ReactComponent as DarkThemeIcon } from "assets/images/theme/dark.svg";
import styled from "styled-components";
import { useTypedDispatch, useTypedSelector } from "hooks/useTypedRedux";
import { setThemeMode } from "redux/slices/appSlice";
import { themeModeSelector } from "redux/selectors/appSelector";

const projectName = process.env.REACT_APP_INSTANCE ?? "izibet";

export const ThemeSwitch = () => {
  const themeMode = useTypedSelector(themeModeSelector);
  const dispatch = useTypedDispatch();

  const toggleTheme = () => {
    const currentTheme = themeMode === "dark" ? "light" : "dark";
    localStorage.setItem(`${projectName}_theme`, currentTheme);
    dispatch(setThemeMode(currentTheme));
    document.documentElement.dataset.theme = currentTheme;
  };

  return (
    <ThemeModeButton id="theme_button" onClick={toggleTheme}>
      {themeMode === "dark" ? <LightThemeIcon /> : <DarkThemeIcon />}
    </ThemeModeButton>
  );
};

const ThemeModeButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.625rem;
  height: 2.625rem;
  border-radius: 0.5rem;

  background-color: ${({ theme }) =>
    theme.mode === "dark" ? "#272727" : theme.palette.secondary.main};

  &:hover {
    background-color: ${({ theme }) =>
      theme.mode === "dark"
        ? theme.palette.primary.black
        : theme.palette.primary.hover};
  }
`;
